import Layout from '../../components/Layout';
import Seo from '../../components/Seo';

import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image/dist/src/components/hooks';
import BlogPage, { BlogSectionWithImage, BlogTextSection, BlogTextTitle } from '../../components/BlogPage';
import { StaticImage } from 'gatsby-plugin-image';

const ReasonsToGetStarted = () => {
  const { placeholderImage }: { placeholderImage: ImageDataLike } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "blog/blog-2.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `,
  );

  return (
    <Layout>
      <Seo title="3 reasons to get started with alternative investments" />
      <BlogPage
        image={placeholderImage}
        title={'3 reasons to get started with alternative investments'}
        subtitle="Secure assets"
        duration={'4min read'}
      >
        <BlogTextSection>
          "Stocks, bonds, cryptocurrencies, funds, retirement solutions... and now you're telling me to read up on
          something again?" Yes, we do. For good reasons. Three, in fact.
        </BlogTextSection>

        <BlogTextTitle title="1. You’re diversifying your portfolio" />
        <BlogTextSection>
          As grandpa said: don't put all your eggs in one basket. That doesn't mean you should just be buying different
          stocks. If there is a stock market crash, you’re still going to get the short end of the stick. Alternative
          investments are particularly stable during this time. The development of the luxury watch market during the
          Corona crisis is the best example. Ask your friend who’s currently failing to get a Rolex – he’ll tell you.
        </BlogTextSection>
        <BlogTextSection>
          If you own some splints of our assets in addition to traditional financial products, you minimize your risk
          and protect yourself from price fluctuations. Recessions suddenly become a lot less dangerous for your
          savings.
        </BlogTextSection>

        <BlogTextTitle title="2. Better performance than (most) stocks" />
        <BlogSectionWithImage
          contentSection={
            <>
              <BlogTextSection>
                Okay, alternative investments require a lot of maintenance. Proper storage, insurance, etc. But hey, we
                take care of that so you can reap the benefits! And one of them is the very solid returns.
              </BlogTextSection>
              <BlogTextSection>
                Let's quickly observe how the prices of such assets have evolved over the past decade. According to the{' '}
                <a href={'https://www.knightfrank.com/wealthreport'}>Knight Frank Report</a>, the categories whiskey
                (+564%), cars (+194%), wine (+120%) and art (+141%) have particularly increased in the last 10 years.
                Not too shabby, right? Of course, even with alternative investments, you have to pick the right ones to
                profit. But hey, we'll do that for you too. How convenient is that?
              </BlogTextSection>
            </>
          }
          image={
            <StaticImage src="../../images/blog/blog-2-inner.png" alt="2. Better performance than (most) stocks" />
          }
        />

        <BlogTextTitle title="3. Alternative investments are tangible" />
        <BlogTextSection>
          Sounds obvious, but it’s actually a decisive point. Sure, you know Tesla or Apple, but stocks or ETFs aren't
          really tangible, are they? If the stock market has always been a bit too abstract and uncertain for your
          taste, then Splints are just the right thing for you.
        </BlogTextSection>

        <BlogTextSection>
          Even if you don't know anything about watches: you know that there's a real watch safely in our storage. And
          you also know what a luxury watch is worth. And in case you already are a watch enthusiast, all the better!
          Then you're buying a piece of something you think is really cool.
        </BlogTextSection>
        <BlogTextSection>
          Of course, there are many more reasons than these three, but the best way to find out is to download the
          Splint app and discover the world of alternative investments yourself. See you in a bit!
        </BlogTextSection>
      </BlogPage>
    </Layout>
  );
};

export default ReasonsToGetStarted;
